<template>
  <Header headerClass="transparent"></Header>
  <div class="login-page">
    <Form class="login" @submit="resetPassword" :initial-values="initialData" :validation-schema="schema">
      <FormWrapper>
        <template #form-head>
          <h2>Reset Password</h2>
          <p>
            We’ll get your new password set up in no time. Make sure this is the correct email address and we’ll send
            your reset link there.
          </p>
        </template>
        <TextInput name="email" type="email" placeholderText="Email" />
        <template #form-submit>
          <CustomButton type="submit" color="primary" label="Send my reset link" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { authTypes } from '@shared/'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import unAuthApi from '@/api/Unauthenticated'
import Header from '@/components/organisms/Header.vue'
import { MutationTypes } from '@/store/messages/mutations'
import { ActionTypes } from '@/store/messages/actions'
export default defineComponent({
  name: 'Login',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const schema = yup.object({
      email: yup.string().required().email().label('Email')
    })
    const initialData = {
      email: route.query.email,
      password: ''
    }

    const resetPassword = async (values: authTypes.SetPassword) => {
      const res = await unAuthApi.recoverPassword(values)
      if (res) {
        store.commit(MutationTypes.SET_MODAL_MESSAGE_COLOR, '#4A57F6')
        store.commit(
          MutationTypes.SET_ERROR_MESSAGE,
          'Reset password link has been sent to your email, please check your email'
        )
        store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
      }
    }

    const message = route.query.message ? 'Your account was successfully validated' : ''

    return { resetPassword, message, schema, initialData }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.login-page
  flex: 1
  margin: 2rem
  .login
    margin: 0 auto
.link-line
  display: flex
  align-self: center
  align-items: center
  justify-content: center
  a
    margin-left: 2px
    text-decoration: none
    font-weight: bold
    color: $purple
.absolute
  bottom: 0
  left: 0
  right: 0
  position: fixed
</style>
