
import { defineComponent, ref } from 'vue'
import AddStudioDetails from '@/components/pages/auth/AddStudioDetails.vue'
import Sidebar from '@/components/pages/auth/Sidebar.vue'

export default defineComponent({
  components: {
    Sidebar,
    AddStudioDetails
  },
  setup() {
    const step = ref(1)
    const nextStep = () => {
      step.value = 2
    }
    return { step, nextStep }
  }
})
