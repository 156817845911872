
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sidebar',
  props: {
    steps: {
      type: Array as () => string[],
      default: () => []
    },
    current: {
      type: Number,
      default: 1
    }
  }
})
