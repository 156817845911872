<template>
  <aside class="sidebar">
    <img class="logo" src="@/assets/images/Studiospace.svg" alt="logo" />
    <h4>Set up your Studiospace</h4>
    <div class="progress">
      <ul>
        <li v-for="(step, index) in steps" :key="index" :class="{ complete: index + 1 < current }">
          <img
            v-if="index + 1 < current"
            class="progress__icon"
            data-testid="progressComplete"
            src="@/assets/images/progress-complete.svg"
            alt=""
          />
          <img v-if="index + 1 == current" class="progress__icon" src="@/assets/images/progress-active.svg" alt="" />
          <img v-if="index + 1 > current" class="progress__icon" src="@/assets/images/progress.svg" alt="" />
          <span class="studio_name">{{ step }}</span>
        </li>
      </ul>
    </div>
    <div class="progress-mobile">
      <span
        v-for="(step, index) in steps"
        :key="index"
        :class="{ active: index + 1 == current }"
        class="progress-mobile__status"
      >
      </span>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sidebar',
  props: {
    steps: {
      type: Array as () => string[],
      default: () => []
    },
    current: {
      type: Number,
      default: 1
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.sidebar
  width: 15.75rem
  padding: 2rem
  background: $white
  text-align: left
  @media( max-width: $md-breakpoint)
    width: 100%
    padding: 1.5rem 2rem
  .logo
    height: 4rem
    margin-top: -2rem
    margin-left: -1.1rem
    @media( max-width: $md-breakpoint)
      display: none
  h4
    color: $primary
    font-weight: 700
    font-size: 1.25rem
    margin-top: 0
    line-height: 1.5
    @media( max-width: $md-breakpoint)
      margin-bottom: 1rem
  .progress
    @media( max-width: $md-breakpoint)
      display: none
    ul
      list-style: none
      padding: 0
      li
        display: flex
        align-items: center
        margin-bottom: 2rem
        position: relative
        &:not(:last-child)::after
          content: ''
          position: absolute
          width: 1.25rem
          height: 1rem
          bottom: -1.5rem
          left: 0
          background-repeat: no-repeat
          background-image: url('~@/assets/images/step.svg')
          background-size: contain
          background-position: center
    &__icon
      width: 1.25rem
      margin-right: 0.625rem
    .complete:not(:last-child)::after
      background-image: url('~@/assets/images/step-complete.svg')
  .progress-mobile
    display: none
    justify-content: space-between
    align-items: center
    @media( max-width: $md-breakpoint)
      display: flex
    &__status
      height: 0.3125rem
      width: 15%
      border-radius: 1.25rem
      background: map-get($greyShades, 'greyShade20')
      &.active
        background: $purple
        width: 45%
        flex: 1
        margin: 0 .5rem
</style>
