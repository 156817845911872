
import { defineComponent, ref } from 'vue'
import { ActionTypes } from '@/store/auth/actions'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import Datepicker from '@/components/atom/DatePicker.vue'
import { useStore } from '@/store'
import { authTypes } from '@shared/'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import Geolocation from '@/components/atom/Geolocation.vue'
import { currencies } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'AddStudioDetails',
  components: {
    CustomButton,
    SelectInput,
    Datepicker,
    FormWrapper,
    Form,
    SingleCheckBox,
    TextInput,
    Geolocation
  },
  emits: ['nextStep'],
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const schemaStep1 = yup.object({
      userFirstName: yup.string().required().label('First name'),
      userLastName: yup.string().required().label('Last name'),
      userEmail: yup.string().required().email().label('Email'),
      userPassword: yup.string().required().min(8).label('Password')
    })

    const schemaStep2 = yup.object({
      studioName: yup.string().required().label('Studio name'),
      studioLegalId: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Only alphabets and numbers are allowed')
        .required('Company number is required'),
      studioAddress: yup.string().required().label('Studio address'),
      studioIncorporatedAt: yup.string().label('Studio incorporated at'),
      studioCountryCode: yup.string().required().min(2).max(2).label('Studio country code'),
      studioMailAddress: yup.string().label('Studio mail address')
    })

    const initialUserValues: authTypes.RegisterUser & { userFirstName: string; userLastName: string } = {
      userEmail: '',
      userPassword: '',
      userFullName: '',
      userFirstName: '',
      userLastName: ''
    }

    const initialStudioValues: authTypes.RegisterStudio = {
      studioName: '',
      studioLegalId: '',
      studioAddress: '',
      studioCountryCode: 'UK',
      studioSize: 'S1_5',
      studioIncorporatedAt: new Date(),
      studioCurrencyCode: 'GBP',
      studioMailAddress: ''
    }

    let formData: authTypes.RegisterBody & { userFirstName: string; userLastName: string } = {
      ...initialUserValues,
      ...initialStudioValues
    }

    const next = (values: authTypes.RegisterUser) => {
      formData = { ...formData, ...values }
      emit('nextStep')
    }

    const register = (values: authTypes.RegisterBody & { mailingAddress: boolean }) => {
      formData = { ...formData, ...values }
      formData.userFullName = formData.userFirstName + ' ' + formData.userLastName
      if (values.mailingAddress === undefined || values.mailingAddress === false) {
        formData.studioMailAddress = formData.studioAddress
      }
      store.dispatch(ActionTypes.REGISTER, formData)
    }

    const currencyCodes = Object.values(currencies).map((item: any) => {
      return {
        value: item.code,
        label: item.code
      }
    })

    return {
      companySizeValues,
      register,
      next,
      initialUserValues,
      initialStudioValues,
      formData,
      schemaStep1,
      schemaStep2,
      currencyCodes
    }
  }
})
