<template>
  <Header headerClass="transparent"></Header>
  <div class="login-page">
    <Form class="login" @submit="resetPassword" :validation-schema="schema">
      <FormWrapper>
        <template #form-head>
          <h2>New password</h2>
          <p>Set your new password</p>
        </template>
        <TextInput name="password" type="password" placeholderText="New password" />
        <TextInput name="confirmPassword" type="password" placeholderText="Confirm password" />
        <template #form-submit>
          <CustomButton type="submit" color="primary" label="Set my new password" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { authTypes } from '@shared/'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import unAuthApi from '@/api/Unauthenticated'
import Header from '@/components/organisms/Header.vue'
import { MutationTypes } from '@/store/messages/mutations'
import { ActionTypes } from '@/store/messages/actions'
export default defineComponent({
  name: 'Login',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const schema = yup.object({
      password: yup.string().required('Password is required'),
      confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('password')], 'Confirm password does not match new password')
        .label('Confirm password')
    })
    const token = route.query.token ? route.query.token.toString() : ''

    const resetPassword = async (values: authTypes.PasswordReset) => {
      try {
        const payload = {
          password: values.password,
          token: token
        }
        const res = await unAuthApi.resetPassword(payload)
        if (res) {
          store.commit(MutationTypes.SET_MODAL_MESSAGE_COLOR, '#4A57F6')
          store.commit(MutationTypes.SET_ERROR_MESSAGE, 'Your password is reset, please login.')
          store.dispatch(ActionTypes.SHOW_ERROR_MESSAGE, true)
          router.push({ name: 'Login' })
        }
      } catch (error) {
        console.log(error)
      }
    }

    return { resetPassword, schema }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.login-page
  flex: 1
  margin: 2rem
  .login
    margin: 0 auto
.link-line
  display: flex
  align-self: center
  align-items: center
  justify-content: center
  a
    margin-left: 2px
    text-decoration: none
    font-weight: bold
    color: $purple
.absolute
  bottom: 0
  left: 0
  right: 0
  position: fixed
</style>
