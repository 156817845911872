<template>
  <div class="wrapper">
    <div class="add-form">
      <Form v-if="step === 1" @submit="next" :validation-schema="schemaStep1" :initial-values="initialUserValues">
        <FormWrapper>
          <template #form-head>
            <h2>First, a bit about you!</h2>
          </template>
          <TextInput
            name="userFirstName"
            type="text"
            placeholderText="First name"
            label="What’s your name?"
            testId="userFirstName"
          />
          <TextInput name="userLastName" type="text" placeholderText="Last Name" testId="userLastName" />
          <TextInput
            name="userEmail"
            type="email"
            placeholderText="Your email"
            label="What’s your email address?"
            testId="userEmail"
          />
          <TextInput
            name="userPassword"
            type="password"
            placeholderText="Your password"
            label="Password"
            testId="userPassword"
          />
          <template #form-submit>
            <CustomButton type="submit" color="primary" size="big" label="Next" testId="firstStepSubmit" />
          </template>
        </FormWrapper>
      </Form>
      <Form
        @submit="register"
        v-if="step === 2"
        :validation-schema="schemaStep2"
        v-slot="{ values }"
        :initial-values="initialStudioValues"
      >
        <FormWrapper>
          <template #form-head>
            <h2>Thanks {{ formData.userFullName }}, now onto your studio</h2>
          </template>
          <TextInput
            label="Your studio name"
            type="text"
            name="studioName"
            placeholderText="Studio name"
            testId="studioName"
          />
          <TextInput
            label="Your company number"
            type="text"
            name="studioLegalId"
            placeholderText="Company number"
            tooltipText="A company registration number is a unique combination of numbers and, in some cases, letters. The company number is used to identify your company and verify the fact that it is a legally registered entity. You can find your (or a client’s or subcontractor’s) company number at your country’s registrar of companies."
            testId="studioLegalId"
          />
          <SelectInput
            label="How many employees do you have?"
            selectLabel="Select number of employees"
            name="studioSize"
            :items="companySizeValues"
            testId="studioSize"
          ></SelectInput>
          <Geolocation
            label="Your studio’s registered address"
            type="text"
            name="studioAddress"
            placeholderText="Registered address"
            id="studioAddress"
            testId="studioAddress"
            inputType="Add"
          />
          <SingleCheckBox
            :label="'This is different to my mailing address'"
            testId="mailingAddressCheck"
            :name="'mailingAddress'"
          />
          <Geolocation
            v-if="values.mailingAddress"
            label="Your mailing address"
            type="text"
            name="studioMailAddress"
            placeholderText="Mailing address"
            id="studioMailAddress"
            testId="studioMailAddress"
            inputType="Add"
          />
          <Datepicker name="studioIncorporatedAt" label="Incorporated on" testId="studioIncorporatedAt"></Datepicker>
          <SelectInput
            label="Select currency code"
            selectLabel="Select currency"
            name="studioCurrencyCode"
            :items="currencyCodes"
            testId="studioCurrencyCode"
          ></SelectInput>
          <template #form-submit>
            <CustomButton type="submit" color="primary" size="big" label="Register" testId="register" />
          </template>
        </FormWrapper>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ActionTypes } from '@/store/auth/actions'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import Datepicker from '@/components/atom/DatePicker.vue'
import { useStore } from '@/store'
import { authTypes } from '@shared/'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import Geolocation from '@/components/atom/Geolocation.vue'
import { currencies } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'AddStudioDetails',
  components: {
    CustomButton,
    SelectInput,
    Datepicker,
    FormWrapper,
    Form,
    SingleCheckBox,
    TextInput,
    Geolocation
  },
  emits: ['nextStep'],
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const schemaStep1 = yup.object({
      userFirstName: yup.string().required().label('First name'),
      userLastName: yup.string().required().label('Last name'),
      userEmail: yup.string().required().email().label('Email'),
      userPassword: yup.string().required().min(8).label('Password')
    })

    const schemaStep2 = yup.object({
      studioName: yup.string().required().label('Studio name'),
      studioLegalId: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Only alphabets and numbers are allowed')
        .required('Company number is required'),
      studioAddress: yup.string().required().label('Studio address'),
      studioIncorporatedAt: yup.string().label('Studio incorporated at'),
      studioCountryCode: yup.string().required().min(2).max(2).label('Studio country code'),
      studioMailAddress: yup.string().label('Studio mail address')
    })

    const initialUserValues: authTypes.RegisterUser & { userFirstName: string; userLastName: string } = {
      userEmail: '',
      userPassword: '',
      userFullName: '',
      userFirstName: '',
      userLastName: ''
    }

    const initialStudioValues: authTypes.RegisterStudio = {
      studioName: '',
      studioLegalId: '',
      studioAddress: '',
      studioCountryCode: 'UK',
      studioSize: 'S1_5',
      studioIncorporatedAt: new Date(),
      studioCurrencyCode: 'GBP',
      studioMailAddress: ''
    }

    let formData: authTypes.RegisterBody & { userFirstName: string; userLastName: string } = {
      ...initialUserValues,
      ...initialStudioValues
    }

    const next = (values: authTypes.RegisterUser) => {
      formData = { ...formData, ...values }
      emit('nextStep')
    }

    const register = (values: authTypes.RegisterBody & { mailingAddress: boolean }) => {
      formData = { ...formData, ...values }
      formData.userFullName = formData.userFirstName + ' ' + formData.userLastName
      if (values.mailingAddress === undefined || values.mailingAddress === false) {
        formData.studioMailAddress = formData.studioAddress
      }
      store.dispatch(ActionTypes.REGISTER, formData)
    }

    const currencyCodes = Object.values(currencies).map((item: any) => {
      return {
        value: item.code,
        label: item.code
      }
    })

    return {
      companySizeValues,
      register,
      next,
      initialUserValues,
      initialStudioValues,
      formData,
      schemaStep1,
      schemaStep2,
      currencyCodes
    }
  }
})
</script>
<style lang="sass" scoped>
// @import '~@/sass/wrapper.sass'
</style>
