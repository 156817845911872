<template>
  <div class="wrapper-main sidebar-open">
    <Sidebar :current="step" :steps="['Your Details', 'Your Studio']" />
    <AddStudioDetails :step="step" @nextStep="nextStep" />
  </div>
  <div class="botton-line">
    <p>Already have an account. <router-link :to="{ name: 'Login' }">Sign in</router-link> now.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AddStudioDetails from '@/components/pages/auth/AddStudioDetails.vue'
import Sidebar from '@/components/pages/auth/Sidebar.vue'

export default defineComponent({
  components: {
    Sidebar,
    AddStudioDetails
  },
  setup() {
    const step = ref(1)
    const nextStep = () => {
      step.value = 2
    }
    return { step, nextStep }
  }
})
</script>

<style scoped lang="sass">
@import '@/sass/wrapper.sass';
.botton-line
  bottom: 0
  left: 0
  right: 0
  position: fixed
  display: flex
  align-self: center
  align-items: center
  justify-content: center
  a
    text-decoration: none
    font-weight: bold
    color: $primary
</style>
